module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"comic-book-wiki","short_name":"cbw","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon-32x32.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3450c8872d43e08ad76907bf1e7b8648"},
    },{
      plugin: require('../node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"wiki.site/wordpress","protocol":"http"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
